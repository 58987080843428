import React , { Component }  from 'react';
import './ButtonChangePage.css';
import axios from 'axios';
import Cookies from 'js-cookie'

// POPUP
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

// -- components
export const getAccessToken = () => Cookies.get('entrada_access_token')


class ButtonChangePage extends Component {

  constructor(props){
    super(props);
    this.state = {
      saldo: 0,
      descontar: false,
      validation: "",
      isModalOpen: false
    }
    this.requestAPI = this.requestAPI.bind(this);
    this.confirmWithNotes = this.confirmWithNotes.bind(this);
  }

  componentDidMount(){
    if(this.props.name === "acrescentar"){
      this.setState({descontar: false})
    }
    // -- if "descontar", make the value negative
    if(this.props.name === "descontar"){
      this.setState({descontar: true})
    }
  }

  // -- validate input
  handleInputValidation(tipo){
    if(this.props.idCartao.length <= 3 ){
      this.props.changeErrorMessage("Número do cartão inválido.");
      return false;
    }
    else if(this.props.valor === 0 || this.props.valor.length === 0){
      this.props.changeErrorMessage("Por favor introduza a quantia a " + tipo + ".");
      return false;
    }
    else if(this.props.valor < 0){
      this.props.changeErrorMessage("Valor não pode ser negativo.");
      return false;
    }
    else if(this.props.idFuncionario.length === 0){
      this.props.changeErrorMessage("Código de Funcionário inválido.");
      return false;
    }
    this.props.changeErrorMessage("");
    return true;
  }

  openModal(){
    this.setState({isModalOpen: true})
  }

  requestAPI(){
    // -- verify input form
    // -- if modal is not open
    if(this.props.name === "descontar"){
      this.openModal();
    }
    else{
      if(this.handleInputValidation(this.props.name)){
        // registar transação
        this.updateSaldoCartao(this.props.idCartao)
      }
    }
  }

  confirmWithNotes(e){
    e.preventDefault();
    if(this.handleInputValidation(this.props.name)){
      this.updateSaldoCartao(this.props.idCartao)
    }
    this.setState({isModalOpen: false})
  }

  updateSaldoCartao(cartao){
    let valor;
    if(this.props.name === "acrescentar"){
      valor = this.props.valor;
      // -- calcular valor a adicionar ao saldo (percentagem do valor total da venda)
      this.calculateValorTransacao(valor, function(response){
        if(!response){
          return;
        }
      })
    }
    // -- if "descontar", make the value negative
    if(this.props.name === "descontar"){
      valor = -this.props.valor;
      this.setState({valorFinal: valor})
    }

    // -- check cliente exists
    // -- check funcionario exists
    let url = "/api/transacao/check_exists.php";
    axios.post(url,
    {cliente: cartao, funcionario: this.props.idFuncionario, username: "entrada", token: getAccessToken()})
    .then(res => {
      // if both exist and are valid (funcionario is active)
      // -- post request to update saldo cliente
      this.registarTransacao(this.state.valorFinal)
      // this.getLastVendaRegistada()
      // let url = "/api/cliente/update_saldo.php";
      // // Make a Post Request
      // axios.post(url, {cartao: cartao, valor: this.state.valorFinal, username: "entrada", token: getAccessToken()})
      // .then((res) => {
      //   this.getLastVendaRegistada()
      // })
      // .catch((error) => {
      //   this.props.changeErrorMessage(error.response.data.message)
      // });
    })
    .catch((error) => {
      this.props.changeErrorMessage(error.response.data.message)
    });

  }

  calculateValorTransacao(valor, callback){
    let url = "/api/config/get-percentagem.php";
    axios.get(url + '?valor=' + valor)
    .then((res) => {
      // // EX: 10% de 20€ ---> 20€ * (10 * 0.01)
      // var result = valor * (res.data.percentagem * 0.01);
      // // -- arredondar à quinta casa decimal (valor 12€ arredondava para 1.2000000000000000000002€)
      // // var rounded = Math.round(result*100)/100;
      // var rounded = parseFloat(result.toFixed(5))
      var rounded = parseFloat(res.data.percentagem)
      this.setState({valorFinal: rounded}, function(){
        callback(false);
      })
    })
    .catch((error) => {
      this.props.changeErrorMessage("Erro a calcular valor final.");
      callback(true);
    })
  }

  // ---- Get valor da última venda registada
  // - Valor é inserido na DB através do endpoint /api/config/registar-venda.php
  // -- pelo sistema de faturação
  getLastVendaRegistada(){
    let url = "/api/config/get-ultima-venda.php"
    axios.get(url)
    .then((res) => {
      if((res.data.valor && res.data.valor > 0) || res.data.message) this.registarTransacao(this.state.valorFinal);
      else this.props.changeErrorMessage("Possível duplicação de dados. Aguarde e tente novamente dentro de 30 segundos.")
    })
    .catch((error) => {
      this.props.changeErrorMessage("Possível duplicação de dados. Aguarde e tente novamente dentro de 30 segundos.")
      this.setState({errorMessage: ""})
    })
  }

  registarTransacao(valorFinal){
    let url = "/api/transacao/create.php";
    // Make a Post Request
    axios({
      method: 'post',
      url: url,
      data: {
        descontar: this.state.descontar,
        valor: valorFinal,
        total_compra: this.props.valor,
        funcionario: this.props.idFuncionario,
        cliente: this.props.idCartao,
        observacoes: this.props.observacoes,
        username: "entrada",
        token: getAccessToken()
      }
    })
    .then((res) => {
      this.props.changeErrorMessage(res.data.message);
      this.getSaldoCliente();
    })
    .catch((error) => {
      this.props.changeErrorMessage(error.response.data.message);
    })
  }

  getSaldoCliente(){
    let url = "/api/cliente/read_one.php";
    axios.get(url + "?cartao=" + this.props.idCartao)
    .then((res) => {
      var valorFinal2Casas = this.state.valorFinal.toFixed(3)
      var success_message = [
        "Sucesso!",
        "A "+this.props.name+": " + valorFinal2Casas + " €.",
        "Saldo atual: " + res.data.saldo + " €"
      ]
      this.props.changeErrorMessage(success_message.join("\n"));

      setTimeout(() => {
        this.props.clearInput();
        window.location.reload();
      }, 3000);
    })
    .catch((error) => {
      this.props.changeErrorMessage("Registado com Sucesso.");
    })
  }

  render(){

    const actions = [
      <div>
      <button className="btn" type="submit" onClick={(e) => {this.confirmWithNotes(e)}}>
        Descontar Saldo
      </button>
      </div>
    ];

    return (
      <div>
        <button id={this.props.name} className="btn request-api" onClick={this.requestAPI} ref={(button) => {this.props.setRef(button, this.props.name)}} style={this.props.style} >
          {this.props.text}
        </button>

        <Dialog
        open={this.state.isModalOpen}
        onClose={() => {this.setState({isModalOpen: false}) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
        <DialogTitle id="alert-dialog-title">A descontar de "{this.props.idCartao}"</DialogTitle>
        <DialogContent>
          <form onSubmit={(e) => {this.confirmWithNotes(e)}}>
            <input type="text" autoFocus placeholder="Observações" name="observacoes" value={this.props.observacoes} onChange={this.props.onChangeInput} style={{width: "80%"}} rows={7} cols={60} maxLength={200} maxAllowed={180}>
            </input>
            {actions}
          </form>
          <button className="btn secondary dark" onClick={() => {this.setState({isModalOpen: false})}}>
            Cancelar
          </button>
        </DialogContent>

        </Dialog>
      </div>
    );
  }
}

export default ButtonChangePage;
