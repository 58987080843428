import React , { Component }  from 'react';
import './InputField.css';
// -- components

class InputField extends Component {

  constructor(props){
    super(props);
    this.state = {
    }

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
  }


  handleKeyDown(event){
    if (event.key === 'Enter') {
      event.preventDefault();
      // -- check if value is larger than maxLength
      // -- > no caso de cliente passar cartão no campo errado
      if(event.target.value.length > this.props.maxAllowed){
        event.target.value = ""
      } else{
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form, event.target);
        // -- don't execute on the last element
        if(index + 1 < form.elements.length){
          // -- check input ID (if it's first input of form)
          if(index === 0){
            this.props.clienteIDIsInserted(form, index);
          }
          else{
            form.elements[index + 1].focus();
          }
        }
        // -- execute "btnAcrescentar" function on parent component
        else this.props.btnAcrescentar.click();
      }
    }
  }

  onChangeInput(e){
    if(this.props.onChangeInput){
      this.props.onChangeInput(e, this.props.name)
    }
  }

  render(){
    return (
      <input className="field" type={this.props.type} ref={this.props.inputRef} placeholder={this.props.placeholder} name={this.props.name} onKeyDown={this.handleKeyDown} value={this.props.value} onChange={this.onChangeInput} maxLength={this.props.maxLength} minLength={0} style={this.props.style} autoFocus={this.props.name==="card_id" ? true : ""} autoCorrect="off" autoComplete="new-password" spellCheck="off" disabled={this.props.disabled} />
    );
  }
}

export default InputField;
