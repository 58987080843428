import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Gestao from './pages/gestao/Gestao';
import RegistarCliente from './pages/RegistarCliente';
import RegistarFuncionario from './pages/gestao/RegistarFuncionario';
import {ListFuncionarios, GerirFuncionarios} from './pages/gestao/GerirFuncionarios';
import ConsultarMovimentosCartao from "./pages_consultas/ConsultarMovimentosCartao";
import ConsultarMovimentosFuncionario from "./pages_consultas/ConsultarMovimentosFuncionario";
import ConsultarMovimentosDia from "./pages_consultas/ConsultarMovimentosDia";
import ConsultarCartoes from "./pages_consultas/ConsultarCartoes";
import { HashRouter, Switch, Route, Link } from 'react-router-dom';
import RegistarCartao from './pages/gestao/RegistarCartao';
import AlterarPassword from './pages/gestao/AlterarPassword';
import AlterarPercentagem from './pages/gestao/AlterarPercentagem';
import { PrivateRouteGestao } from './PrivateRouteGestao';
import { PrivateRoute } from './PrivateRoute';
import EnviarSMS from './pages/gestao/EnviarSMS';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import ExportarContactos from './pages/gestao/ExportarContactos';

// Or Create your Own theme:
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#CAB582'
    },
    secondary: {
      main: '#8d6e63'
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
    <HashRouter>
      <Link to="/"><img className="logo" src="./logo.png" alt="Espaço das Natas" /></Link>
      <Switch>
        <Route path="/" exact={true} component={App} />
        <PrivateRoute path="/registar" exact={true} component={RegistarCliente} />
        <PrivateRoute path="/consultar-cartao" exact={true} component={ConsultarMovimentosCartao} />

        <Route path="/gestao" exact={true} component={Gestao} />
        <PrivateRouteGestao  path="/gestao/consultar-tudo" exact={true} component={ConsultarCartoes} />
        <PrivateRouteGestao  path="/gestao/consultar-por-cartao" exact={true} component={ConsultarMovimentosCartao} />
        <PrivateRouteGestao  path="/gestao/consultar-por-dia" exact={true} component={ConsultarMovimentosDia} />
        <PrivateRouteGestao  path="/gestao/consultar-por-funcionario" exact={true} component={ConsultarMovimentosFuncionario} />
        <PrivateRouteGestao  path="/gestao/registar-funcionario" exact={true} component={RegistarFuncionario} />

        <PrivateRouteGestao  path="/gestao/registar-cartao" exact={true} component={RegistarCartao} />
        <PrivateRouteGestao  path="/gestao/alterar-password" exact={true} component={AlterarPassword} />
        <PrivateRouteGestao  path="/gestao/alterar-percentagem" exact={true} component={AlterarPercentagem} />
        <PrivateRouteGestao  path="/gestao/gerir-funcionarios" exact={true} component={ListFuncionarios} />
        <PrivateRouteGestao  path="/gestao/gerir-funcionario/:id" exact={true} component={GerirFuncionarios} />

          <PrivateRouteGestao path="/gestao/exportar-contactos" exact={true} component={ExportarContactos} />
      </Switch>
    </HashRouter>
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
