import React , { Component }  from 'react';
import {Link} from 'react-router-dom'
import axios from 'axios';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Row from "../components/Row";
import TablePagination from '@material-ui/core/TablePagination';

import Cookies from 'js-cookie'
export const getAccessToken = () => Cookies.get('gestao_access_token')
const getAdminUsername = () => Cookies.get('gestao_admin_username')

class Consultas extends Component {

  constructor(props){
    super(props);
    this.state = {
      clients: [""],
      page: 0,
      rowsPerPage: 5
    }
  }

  componentDidMount(){
    this.getListData();
  }

  getListData(){
    // -- get json data from url
    let url = "/api/cliente/get.php";
    axios.post(url, { token: getAccessToken(), username: getAdminUsername() })
    .then(res => {
      var clients = res.data.records;
      // get list from database
      this.setState({clients: clients})
      // -- add empty input
    })
    .catch((error) => console.log("Can’t access " + url + " response. " + error))
  }


  handleChangePage = (event, newPage) => {
    this.setState({page: newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: parseInt(event.target.value, 10)})
    this.setState({page: 0});
  };


  render(){

    // https://material-ui.com/pt/components/tables/
    return (
      <div className="App">
        <Link to="/gestao"><h3 className="title">{"<- Voltar a Gestão"}</h3></Link>
        <h1>Lista de Clientes</h1>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Cartão</TableCell>
                <TableCell align="right">Nome</TableCell>
                <TableCell align="right">Saldo</TableCell>
                <TableCell align="right">Ver</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.clients.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                return <Row key={row.name} row={row} />
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 15, 50, 100]}
            component="div"
            count={this.state.clients.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    );
  }
}

export default Consultas;
