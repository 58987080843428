import React , { Component }  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('gestao_access_token')
const getAdminUsername = () => Cookies.get('gestao_admin_username')

class AlterarPassword extends Component {

  constructor(props){
    super(props);
    this.state = {
      password_atual: '',
      password_nova: '',
      password_nova_confirmar: '',
      validation: "",
      username: getAdminUsername(),
      token: getAccessToken()
    }

    this.clearForm = this.clearForm.bind(this);
  }

  clearForm(){
    this.setState({
      password_atual: '',
      password_nova: '',
      password_nova_confirmar: '',
      validation: ""
    })
  }

  handleFormSubmit(event){
    // -- get json data from url
    event.preventDefault();

    if(this.state.password_nova != this.state.password_nova_confirmar) {
      this.setState({validation: "A password nova e a confirmação devem ser iguais."})
    } else {
      let url = "/api/config/alterar-password.php";
      // Make a Post Request
      axios({
        method: 'post',
        url: url,
        data: this.state
      })
      .then((res) => {
        this.setState({validation: res.data.message}, function(){
          setTimeout(function(){
            this.clearForm();
          }.bind(this), 1000);
        })
      })
      .catch((error) => {
        this.setState({validation: error.response.data.message})
        setTimeout(function(){
          this.setState({validation: ""})
        }.bind(this), 2000);
      });
    }

  }

  render(){
    return (
      <div className="App">
        <Link to="/gestao"><h3 className="title">{"<- Voltar a Gestão"}</h3></Link>
        <h1>Alterar Password</h1>
        <form method="POST" className="initial-inputs">
          <input name="password_atual" type="password" maxLength="20" placeholder="Password Atual" value={this.state.password_atual} onChange={e => this.setState({ password_atual: e.target.value })} />
          <input name="password_nova" type="password" maxLength="20" placeholder="Password Nova" value={this.state.password_nova} onChange={e => this.setState({ password_nova: e.target.value })} />
          <input name="password_nova_confirmar" type="password" maxLength="20" placeholder="Confirmar Password Nova" value={this.state.password_nova_confirmar} onChange={e => this.setState({ password_nova_confirmar: e.target.value })} />
          <br></br>
          <h5 className="notification-text">{this.state.validation}</h5>
          <br/>
          <button type="submit" className="btn" onClick={e => this.handleFormSubmit(e)}>Confirmar</button>
        </form>
      </div>
    );
  }
}

export default AlterarPassword;
