import React , { Component }  from 'react';
import { Link } from 'react-router-dom';
import './App.css';
// -- components
import InputField from "./components/InputField";
import ButtonFunction from "./components/ButtonFunction";
import axios from 'axios';
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('entrada_access_token')

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      pages:[
        {
          title: "Consultas",
          url: "/consultas"
        },
        {
          title: "Registar Cliente",
          url: "/registar"
        },
      ],
      idCartao: "",
      idFuncionario: "",
      valor: "",
      observacoes: "",
      codigo_acesso: "",
      errorMessage: "",
      hasClientID: false,
    }

    // -- create Refs to handle click manually (when pressing "Enter" click on "Acrescentar Saldo")
    this.btnAcrescentar = React.createRef();
    this.btnDescontar = React.createRef();
    this.firstInput = useFocus();

    // -- bind every function to be able to use "this" on them
    this.onChangeInput = this.onChangeInput.bind(this);
    this.changePage = this.changePage.bind(this);
    this.changeErrorMessage = this.changeErrorMessage.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.setRef = this.setRef.bind(this);
    this.clienteIDIsInserted = this.clienteIDIsInserted.bind(this);
  }

  componentDidMount(){
    // -- check if user is logged in
    // -- (cookie exists and is valid)
    this.checkLogin("checking");
  }

  onChangeInput(e, name){
    if(e.target.name === "card_id"){
      this.setState({idCartao: e.target.value})
    }
    if(e.target.name === "funcionario_id"){
      this.setState({idFuncionario: e.target.value})
    }
    if(e.target.name === "preco_total"){
      // verificar se preço a alterar é o preço obtido do sistema de faturação
      // caso seja alterado este valor, pedir o número ao funcionário
      if(this.state.successGetValorDB){
        this.setState({successGetValorDB: false, idFuncionario: ""})
      }
      this.setState({valor: e.target.value})
    }
    if(e.target.name === "observacoes"){
      this.setState({observacoes: e.target.value})
    }
  }

  clearInput(){
    this.setState({idCartao: "", idFuncionario: "", valor: "", observacoes: ""})
    this.setState({hasClientID: false}, function(){
      // focus on the first input element
      this.firstInput.setFocus();
    })
    setTimeout(function(){
      this.setState({errorMessage: ""})
    }.bind(this), 4000);
  }

  changePage(url){
    this.props.history.push(url + "?cartao=" + this.state.idCartao);
  }

  handleLoginValidation(e){
    e.preventDefault();
    this.checkLogin("login");
  }

  changeErrorMessage(errorMessage){
    this.setState({errorMessage: errorMessage})
    setTimeout(function(){
      this.setState({errorMessage: ""})
    }.bind(this), 3000);
  }

  setRef(button, name){
    if(name === "acrescentar"){
      this.btnAcrescentar = button;
    }
    if(name === "descontar"){
      this.btnDescontar = button;
    }
  }

  checkLogin(page){
    let accessCode;
    if(page === "login"){
      accessCode = this.state.codigo_acesso;
    } else{
      accessCode = getAccessToken();
    }
    if(accessCode){
      let url = "/api/config/check-token.php";
      // Make a Post Request
      axios({
        method: 'post',
        url: url,
        data: {
          username: "entrada",
          token: accessCode
        }
      })
      .then((res) => {
        this.setState({errorMessage: ""})
        this.setState({isAuthenticated: true})

        if(page === "login"){
          // -- generate expiry date
          const almostNever = 99983090;
          Cookies.set('entrada_access_token', accessCode, {expires: almostNever})
        }

      })
      .catch((error) => {
        if(error.response.status !== 200){
          this.setState({errorMessage: "Código de sessão inválido. Tente novamente."})
        }
      });
    }
  }

  clienteIDIsInserted(form, index){
    // -- check inserted ID is valid
    let url = "/api/cliente/read_one.php"
    axios.get(url + "?cartao=" + this.state.idCartao)
    .then((res) => {
      this.setState({errorMessage: "", hasClientID: true}, () => {
        // get last value
        form.elements[index + 1].focus();
      })
    })
    .catch((error) => {
      this.setState({errorMessage: error.response.data.message})
      this.clearInput();
    })
  }

  render(){
    let hasClientID = !this.state.hasClientID ? "none" : "";

    return (
      <div>
      { this.state.isAuthenticated ? (
      <div className="App">
        { this.state.errorMessage && <h5 className="notification-text">{this.state.errorMessage}</h5>}
        <br/>
        <form className="initial-inputs">
          {/* disable changing of "card_id" if card was already entered (onChangeInput is passed undefined) */}
          <InputField placeholder="Leitura do Cartão" name="card_id" inputRef={this.firstInput.ref} value={this.state.idCartao} onChangeInput={this.onChangeInput} clienteIDIsInserted={this.clienteIDIsInserted} type="text" maxLength={20} maxAllowed={15} disabled={!this.state.hasClientID ? false : true} />

          <InputField placeholder="Valor" name="preco_total" value={this.state.valor} onChangeInput={this.onChangeInput} style={{display: hasClientID}} type="number" maxLength={8} maxAllowed={6} />

          {!this.state.successGetValorDB && <InputField placeholder="Funcionário" name="funcionario_id" value={this.state.idFuncionario} onChangeInput={this.onChangeInput} style={{display: hasClientID}} type="password" btnAcrescentar={this.btnAcrescentar} maxLength={20} maxAllowed={6}/>}
        </form>

        <div className="buttons-section">
          <ButtonFunction text="Acrescentar Saldo" name="acrescentar" idCartao={this.state.idCartao} idFuncionario={this.state.idFuncionario} valor={this.state.valor} changeErrorMessage={this.changeErrorMessage} clearInput={this.clearInput} style={{display: hasClientID}} setRef={this.setRef}/>

          <ButtonFunction text="Descontar Saldo" name="descontar" idCartao={this.state.idCartao} idFuncionario={this.state.idFuncionario} valor={this.state.valor} observacoes={this.state.observacoes} changeErrorMessage={this.changeErrorMessage} clearInput={this.clearInput} onChangeInput={this.onChangeInput} style={{display: hasClientID}} setRef={this.setRef}/>

          <button className="btn limpar" style={{display: hasClientID}} onClick={this.clearInput}>Limpar</button>

          <Link to={{pathname: "/consultar-cartao", idCartao: this.state.idCartao, gestao: false}}>
            <button className="btn consultar-cartao" style={{display: hasClientID}} >Consultar Cartão</button>
          </Link>
        </div>

        <div>
          <Link to={{
            pathname: "/registar",
            idCartao: this.state.idCartao
          }}>Registar Cliente</Link>
          <br></br>
        </div>
      </div> ) : (
        <div className="App">
        <h2>Iniciar Sessão</h2>
        <form>
          <input type="text" className="codigo-acesso" placeholder="Código de Acesso" onChange={e => this.setState({ codigo_acesso: e.target.value })} maxLength={100} minLength={0}/>
          <input type="submit" className="btn" value="Entrar" onClick={e => this.handleLoginValidation(e)}/>
        </form>
        { this.state.errorMessage && <h5 className="notification-text">{this.state.errorMessage}</h5>}
      </div>
      )}
      </div>
    );
  }
}


const useFocus = () => {
  const ref = React.createRef()
  const setFocus = () => {ref.current &&  ref.current.focus()}
  return {setFocus, ref}
}

export default App;
