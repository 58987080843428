import React, { Component, useEffect, useState } from 'react';
import Cookies, { set } from 'js-cookie'
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import ExportTable from '../../components/ExportTable';

export const getAccessToken = () => Cookies.get('gestao_access_token')
const getAdminUsername = () => Cookies.get('gestao_admin_username')

// Nota: todos os telemoveis devem começar com 351 para serem válidos na utilização da API
// -> Nos números sem 351 - adicionar
// -> Nos números começados por +351 - retirar o "+"
// -> Nos números começados por 351 - deixar como está
const getApenasClientesValidosomTelemovel = (cliente) => {
    // cliente não tem telefone
    // não apresentar
    if (!cliente.telefone)
        return
    // verificar se o telemóvel é valido
    // começa por 9
    // --- contém 9 digitos
    if (cliente.telefone.startsWith("9") && cliente.telefone.length === 9) {
        cliente.telefone = '351' + cliente.telefone
        return cliente;
    }
    // contém +351
    // --- contém 9 + 4 digitos
    else if (cliente.telefone.startsWith("+3519") && cliente.telefone.length === 13) {
        cliente.telefone = cliente.telefone.substring(1);
        return cliente;
    }
    // contém 351
    // --- contém 9 + 3 digitos
    else if (cliente.telefone.startsWith("3519") && cliente.telefone.length === 12) {
        return cliente;
    }
}


const ExportarContactos = () => {
    const [contactosSelecionados, setContactosSelecionados] = useState([])
    const [contactos, setContactos] = useState([])
    const [validation, setValidation] = useState("")
    const [selectAll, setSelectAll] = useState(true)

    // Executado quando a página é carregada
    useEffect(() => {
        getContactos();
    }, [])

    // É executado sempre que o valor da variável "selectAll" é alterado
    // --> Sempre que se clica na checkbox "Exportar Todos"
    useEffect(() => {
        // registar todos os contactos como selecionados
        if (selectAll)
            setContactosSelecionados(contactos.map(contacto => contacto.telefone))
    }, [selectAll])

    useEffect(() => {
        // if (contactos.length < 50)
        //     setContactos(contactosOld => [...contactosOld, contactos])
    }, [contactos])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // obter contactos que serão exportados (apenas os que forem selecionados)
        let contactosAExportar = contactos.filter(contacto => contactosSelecionados.includes(contacto.telefone))
        // remover dados desnecessários de cada contacto
        contactosAExportar = contactosAExportar.map(({ nome, telefone }) => ({ telefone, nome }));

        // gerar CSV
        // join('\n') do map dos contactosAExportar -> divisão entre cada linha
        // join(',') do map do Object.keys(contacto) -> cada propriedade de cada contacto fica unida por virgulas
        let csvContent = "data:text/csv;charset=utf-8,"
            + "telefone,nome\n"
            + contactosAExportar.map(contacto => Object.keys(contacto).map(
                (k) => { return contacto[k] }
            ).join(",")).join('\n')
        // download csv
        var encodedUri = encodeURI(csvContent);
        window.open(encodedUri);
    }

    const handleCopiar = () => {
        let contactosAExportar = contactos.filter(contacto => contactosSelecionados.includes(contacto.telefone))
        contactosAExportar = contactosAExportar.map(({ id, nome, telefone }) => ({ telefone, nome }));

        var auxElement = document.createElement("textarea");
        // to avoid breaking orgain page when copying more words
        // cant copy when adding below this code
        // auxElement.style.display = 'none'
        document.body.appendChild(auxElement);
        //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
        auxElement.value = contactosAExportar.map(contacto => Object.keys(contacto).map(
            (k) => { return contacto[k] }
        ).join(",")).join('\n');
        auxElement.select();
        document.execCommand("copy");
        document.body.removeChild(auxElement);
    }

    const getContactos = () => {
        let url = "/api/cliente/get.php";
        axios.post(url, { token: getAccessToken(), username: getAdminUsername() })
            .then((res) => {
                let clientes = res.data.records;
                // remover clientes sem telemovel da lista
                let clientesComTelemovel = clientes.filter(getApenasClientesValidosomTelemovel)

                // registar todos os contactos como selecionados, por defeito
                setContactos(clientesComTelemovel)
                setContactosSelecionados(clientesComTelemovel.map(contacto => contacto.telefone))
            })
            .catch((error) => {
                console.log(error.message);
                setValidation("Não foi possível obter os clientes.")
            })
    }

    const onChangeSelectContacto = (e, contacto) => {
        const selected = e.target.checked;
        if (selected) {
            // adicionar contacto à lista de contactos selecionados
            setContactosSelecionados(contactosSelecionados => [...contactosSelecionados, contacto.telefone]);
        } else {
            // remover contacto da lista de contactos selecionados
            setContactosSelecionados(contactosSelecionados.filter(telefone => telefone !== contacto.telefone));
            setSelectAll(false)
        }
    }

    const returnSelectedRows = (selected) => {
        setContactosSelecionados(selected)
    }

    return (
        <div className="App">
            <Link to="/gestao"><h3 className="title">{"<- Voltar a Gestão"}</h3></Link>
            <h1>Exportar Contactos</h1>

            <TableContainer component={Paper}>
                <ExportTable
                    headCells={[
                        { id: 'nome', numeric: false, disablePadding: true, label: 'Nome' },
                        { id: 'telefone', numeric: true, disablePadding: false, label: 'Contacto' },
                    ]}
                    rows={contactos}
                    tableTitle={'Contactos'}
                    returnSelectedRows={returnSelectedRows}
                />
            </TableContainer>
            <h5 className="notification-text">{validation}</h5>
            <div>
                <button type="submit" className="btn" onClick={handleFormSubmit}>Exportar</button>
                <button type="submit" className="btn secondary" onClick={handleCopiar}>Copiar</button>
            </div>
        </div>
    )
}

export default ExportarContactos;