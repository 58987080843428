import React , { Component }  from 'react';
import './RegistarFuncionario.css';
import axios from 'axios';
import {Link, Redirect} from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RowFuncionario from "../../pages_consultas/RowFuncionario";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('gestao_access_token')
const getAdminUsername = () => Cookies.get('gestao_admin_username')

class ListFuncionarios extends Component{
  constructor(props){
    super(props);
    this.state = {
      funcionarios: []
    }
  }

  componentDidMount(){
    this.getAllFuncionarios();
  }

  getAllFuncionarios(){
    // -- get json data from url
    let url = "/api/funcionario/get.php";
    axios.post(url, { token: getAccessToken(), username: getAdminUsername() })
    .then(res => {
      var funcionarios = res.data.records;
      // get list from database
      this.setState({funcionarios: funcionarios})
      // -- add empty input
    })
    .catch((error) => console.log("Can’t access " + url + " response. " + error))
  }

  render(){
    return(
      <div className="App">
        <Link to="/gestao"><h3 className="title">{"<- Voltar a Gestão"}</h3></Link>
        <h1>Lista de Funcionários</h1>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell align="right">Nome</TableCell>
                <TableCell align="right">Telefone</TableCell>
                <TableCell align="right">Email</TableCell>
                <TableCell align="right">Código</TableCell>
                <TableCell align="right">Data Registo</TableCell>
                <TableCell align="right">Editar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.funcionarios.map((row) => (
                <RowFuncionario key={row.name} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }


}

class GerirFuncionarios extends Component {

  constructor(props){
    super(props);
    this.state = {
      nome: "",
      telefone: "",
      email: "",
      codigo: "",
      openModal: false,
      errorMessage: "",
      username: getAdminUsername(),
      token: getAccessToken()
    }

    this.handleInputChange = this.handleInputChange.bind(this)
  }

  componentDidMount(){
    if(this.props.location.funcionario){
      this.setState({
        id: this.props.location.funcionario.id,
        nome: this.props.location.funcionario.nome,
        email: this.props.location.funcionario.email,
        telefone: this.props.location.funcionario.telefone,
        codigo: this.props.location.funcionario.codigo
      })
    }
  }


  handleInputChange(event){
    // -- check what input made the change
    if(event.target.name === "nome"){
      // -- receives the currentState values
      // -- keeps everything the same except the one to change
      this.setState({
        nome: event.target.value
      })
    }
    if(event.target.name === "telefone"){
      this.setState({
        telefone: event.target.value
      })
    }
    if(event.target.name === "email"){
      this.setState({
        email: event.target.value
      })
    }
    if(event.target.name === "codigo"){
      this.setState({
        codigo: event.target.value
      })
    }
  }

  handleFormSubmit(event){
    event.preventDefault();

    let url = "/api/funcionario/update.php";
    // Make a Post Request
    axios({
      method: 'post',
      url: url,
      data: this.state
    })
    .then((res) => {
      this.setState({errorMessage: res.data.message})
    })
    .catch((error) => this.setState({errorMessage: error.response.data.message}));
  }

  openCheckDeleteModal(){
    this.setState({openModal: true});
  }

  deleteFuncionario(event){
    // -- get json data from url
    event.preventDefault();

    let url = "/api/funcionario/delete.php";
    // Make a Post Request
    axios({
      method: 'post',
      url: url,
      data: this.state
    })
    .then((res) => {
      this.setState({openModal: false})
      return <Redirect to={"/gestao/gerir-funcionarios"} />
    })
    .catch((error) => console.log("Can’t access " + url + " response. " + error));
  }

  render(){
    if(!this.props.location.funcionario) return <Redirect to="/gestao/gerir-funcionarios" />
    return (
      <div className="App">
        <Link to="/gestao/gerir-funcionarios"><h3 className="title">{"<- Voltar aos funcionários"}</h3></Link>
        <form method="POST">
          <label>Alterar Código</label>
          <input name="codigo" type="text" defaultValue={this.props.location.funcionario.codigo} onChange={this.handleInputChange}></input>

          <label>Alterar Nome</label>
          <input name="nome" type="text" defaultValue={this.props.location.funcionario.nome} onChange={this.handleInputChange}></input>

          <label>Alterar Telefone</label>
          <input name="telefone" type="number" defaultValue={this.props.location.funcionario.telefone} onChange={this.handleInputChange}></input>

          <label>Alterar Email</label>
          <input name="email" defaultValue={this.props.location.funcionario.email} onChange={this.handleInputChange}></input>
          <br></br>
          {this.state.errorMessage && <h5 className="notification-text">{this.state.errorMessage}</h5>}
          <button type="submit" className="btn" onClick={e => this.handleFormSubmit(e)}>Atualizar</button>
        </form>
        <button className="btn danger secondary" onClick={e => this.openCheckDeleteModal(e)}>Eliminar</button>

        <Dialog
          open={this.state.openModal}
          onClose={() => {this.setState({openModal: false}) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Tem a certeza que pretende eliminar este funcionário?"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Está prestes a eliminar o funcionário <strong>{this.state.nome}</strong> com o ID <strong>{this.state.id}</strong>. Confirmar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="btn secondary dark" onClick={() => {this.setState({openModal: false})}}>
              Cancelar
            </button>
            <button className="btn danger" onClick={(e) => {this.deleteFuncionario(e)}}>
              Eliminar
            </button>
          </DialogActions>
        </Dialog>

      </div>

    );
  }
}

export {ListFuncionarios, GerirFuncionarios};
