import React , { Component }  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RowTransacao from "./RowTransacao";

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import {pt} from 'date-fns/locale';
import format from 'date-fns/format';
import DateFnsUtils from '@date-io/date-fns';
import TablePagination from '@material-ui/core/TablePagination';



class Consultas extends Component {

  constructor(props){
    super(props);
    this.state = {
      transacoes: [""],
      idCartao: 0,
      date: new Date(),
      dateText: format(new Date(), 'dd-MM-yyyy'),
      page: 0,
      rowsPerPage: 5
    }

    this.getListData = this.getListData.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
  }

  componentDidMount(){
    this.setState({idCartao: this.props.location.idCartao})
  }

  getListData(e = null) {
    if (e)
      e.preventDefault();
    if (typeof this.state.date !== 'undefined') {
      // -- get json data from url
      let url = "/api/transacao/get_por_dia.php";
      var year = this.state.date.getFullYear();
      var month = this.state.date.getMonth() + 1;
      var day = this.state.date.getDate();
      axios.get(url + "?year=" + year + "&month=" + month + "&day=" + day)
        .then(res => {
          // get list from database
          this.setState({ transacoes: res.data.records })
          // -- add empty input
        })
        .catch((error) => console.log("Can’t access " + url + " response. " + error))
    }
  }

  onChangeDate(date){
    console.log(format(date, 'dd-MM-yyyy'))
    this.setState({
      date: date,
      dateText:format(date, 'dd-MM-yyyy')
    })
  }


  handleChangePage = (event, newPage) => {
    this.setState({page: newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: parseInt(event.target.value, 10)})
    this.setState({page: 0});
  };


  render(){

    // https://material-ui.com/pt/components/tables/
    return (
      <div className="App">
        <Link to="/gestao"><h3 className="title">{"<- Voltar a Gestão"}</h3></Link>
        <h1>Consultar por Dia</h1>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={pt}>
          <DatePicker
            disableToolbar
            autoOk
            value={this.state.date}
            formatDate={date => { return format(date, 'dd-MM-yyyy') } }
            onChange={this.onChangeDate}
            label="Selecionar Data"
            invalidDateMessage="Formato de data inválido."
            className="date-picker"
            variant="inline"
            />

        </MuiPickersUtilsProvider>
        <button className="btn button-reload-data" onClick={this.getListData}>Obter</button>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Tipo</TableCell>
                <TableCell align="right">Valor</TableCell>
                <TableCell align="right">Funcionário</TableCell>
                <TableCell align="right">Cliente</TableCell>
                <TableCell align="right">Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.transacoes.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                return <RowTransacao key={row.name} row={row} />
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 15, 50, 100]}
            component="div"
            count={this.state.transacoes.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    );
  }
}

export default Consultas;
