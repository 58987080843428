import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleOutlineSharpIcon from '@material-ui/icons/RemoveCircleOutlineSharp';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


function RowTransacao(props) {
  const { row } = props;
  const classes = useRowStyles();

  // -- check type of transacao
  const isDescontar = row.descontar === 1 ? true : row.descontar === 0 ? false : null;

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {isDescontar ? (
            <RemoveCircleOutlineSharpIcon/>
          ) : isDescontar === false ? (
            <AddCircleIcon />
          ) : (
            <h1></h1>
          )}
        </TableCell>
        {row.valor ? <TableCell align="right"> {row.valor} € </TableCell> : ""}
        <TableCell align="right">{row.funcionario}</TableCell>
        <TableCell align="right">{row.cliente}</TableCell>
        <TableCell align="right">{row.observacoes}</TableCell>
        <TableCell align="right">{row.data}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default RowTransacao;