import React , { Component }  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('gestao_access_token')
const getAdminUsername = () => Cookies.get('gestao_admin_username')

class AlterarPercentagem extends Component {

  constructor(props){
    super(props);
    this.state = {
      percentagem_nova: "",
      validation: "",
      username: getAdminUsername(),
      token: getAccessToken()
    }

    this.clearForm = this.clearForm.bind(this);
  }

  componentDidMount(){
    this.getPercentagemAtual();
  }

  clearForm(){
    this.setState({
      validation: ""
    })
  }

  getPercentagemAtual(){
    let url = "/api/config/get-percentagem.php";
    // Make a Post Request
    axios({
      method: 'get',
      url: url,
    })
    .then((res) => {
      this.setState({validation: res.data.message, percentagem_nova: res.data.percentagem})
    })
    .catch((error) => {
      this.setState({validation: error.response.data.message})
      setTimeout(function(){
        this.setState({validation: ""})
      }.bind(this), 2000);
    });
  }

  handleFormSubmit(event){
    // -- get json data from url
    event.preventDefault();

    let url = "/api/config/alterar-percentagem.php";
    // Make a Post Request
    axios({
      method: 'post',
      url: url,
      data: this.state
    })
    .then((res) => {
      this.setState({validation: res.data.message}, function(){
        setTimeout(function(){
          this.clearForm();
        }.bind(this), 2000);
      })
    })
    .catch((error) => {
      this.setState({validation: error.response.data.message})
      setTimeout(function(){
        this.setState({validation: ""})
      }.bind(this), 2000);
    });
  }

  render(){
    return (
      <div className="App">
        <Link to="/gestao"><h3 className="title">{"<- Voltar a Gestão"}</h3></Link>
        <h1>Alterar Percentagem</h1>
        <form method="POST" className="initial-inputs">
          <input name="percentagem_nova" type="text" maxLength="5" placeholder="Percentagem Nova" value={this.state.percentagem_nova} onChange={e => this.setState({ percentagem_nova: e.target.value })} />
          <br></br>
          <h5 className="notification-text">{this.state.validation}</h5>
          <br/>
          <button type="submit" className="btn" onClick={e => this.handleFormSubmit(e)}>Confirmar</button>
        </form>
      </div>
    );
  }
}

export default AlterarPercentagem;
