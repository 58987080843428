import React , { useEffect }  from 'react';
import axios from 'axios';

import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Link } from 'react-router-dom';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  // const [transacoes, setTransacoes] = React.useState([]);
  const classes = useRowStyles();

  useEffect(() => {
    // -- get json data from url
    let url = "/api/transacao/read_one.php";
    axios.get(url + "?cartao=" + row.cartao)
    .then(res => {
    })
    .catch((error) => console.log("Can’t access " + url + " response. " + error))
  })

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.cartao}</TableCell>
        <TableCell align="right">{row.nome}</TableCell>
        <TableCell align="right">{row.saldo}</TableCell>
        <TableCell component={Link} to={{pathname:"/gestao/consultar-por-cartao", idCartao: row.cartao, gestao: true}} align="right">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <VisibilityIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default Row;