import React from 'react';
import {Redirect, Route} from 'react-router-dom';
import Cookies from 'js-cookie';

export const PrivateRouteGestao = ({
  component: Component, ...rest
}) => {

  return (
    <Route
      {...rest}
      render={props =>
        Cookies.get('gestao_access_token') ? (
        <Component {...props} />
      ) : (
        <Redirect
          to="/gestao" />
      )}
    />
  )
}