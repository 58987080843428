import React , { Component }  from 'react';
import './RegistarFuncionario.css';
import axios from 'axios';
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('gestao_access_token')
const getAdminUsername = () => Cookies.get('gestao_admin_username')

class RegistarFuncionario extends Component {

  constructor(props){
    super(props);
    this.state = {
      nome: '',
      telefone: '',
      email: '',
      codigofuncionario: '',
      validation: "",
      username: "admin",
      token: null
    }

    this.clearForm = this.clearForm.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount() {
    var adminToken = getAccessToken()
    var adminUsername = getAdminUsername()
    this.setState({token: adminToken, username: adminUsername})
  }

  clearForm(){
    this.setState({
      nome: '',
      telefone: '',
      email: '',
      codigofuncionario: '',
      validation: "",
    })
  }

  handleKeyDown(event){
    if(event.key === "Enter"){
      event.preventDefault();
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      // -- don't execute on the last element
      if(index + 1 < form.elements.length){
        form.elements[index + 1].focus();
      }
      else{
        event.currentTarget.click();
      }
    }
  }

  handleFormSubmit(event){
    // -- get json data from url
    event.preventDefault();

    let url = "/api/funcionario/create.php";
    // Make a Post Request
    axios({
      method: 'post',
      url: url,
      data: this.state
    })
    .then((res) => {
      this.setState({validation: res.data.message}, function(){
        setTimeout(function(){
          this.clearForm();
        }.bind(this), 2000);
      })
    })
    .catch((error) => {
      if(error.response.status === 503 || error.response.status === 400){
        this.setState({validation: error.response.data.message})
      }
      if(error.response.status === 500){
        // já existe um funcionário com este código
        // -- ou
        // não é um número
        this.setState({validation: "Código inválido."})
      }
      setTimeout(function(){
        this.setState({validation: ""})
      }.bind(this), 2000);
    });
  }

  render(){
    return (
      <div className="App">
        <Link to="/gestao"><h3 className="title">{"<- Voltar a Gestão"}</h3></Link>
        <h1>Registar Funcionário</h1>
        <form method="POST" className="initial-inputs">
          <input name="codigofuncionario" type="password" maxLength="40" pattern="\d+" placeholder="Código do Funcionário" value={this.state.codigofuncionario} onChange={e => this.setState({ codigofuncionario: e.target.value })} onKeyDown={this.handleKeyDown} autoComplete="new-password" autoCorrect="off" spellCheck="off" />

          <input name="nome" type="text" placeholder="Nome" value={this.state.nome} onChange={e => this.setState({ nome: e.target.value })} maxLength={60} onKeyDown={this.handleKeyDown} autoComplete="new-password" autoCorrect="off" spellCheck="off" />

          <input name="telefone" type="number" placeholder="Telefone" value={this.state.telefone} onChange={e => this.setState({ telefone: e.target.value })} minLength={9} maxLength={9} onKeyDown={this.handleKeyDown} autoComplete="new-password" autoCorrect="off" spellCheck="off" />

          <input name="email" type="email" placeholder="Email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} maxLength={90} onKeyDown={this.handleKeyDown} autoComplete="new-password" autoCorrect="off" spellCheck="off" />
          <br></br>
          <h5 className="notification-text">{this.state.validation}</h5>
          <br/>
          <button type="submit" className="btn" onClick={e => this.handleFormSubmit(e)}>Registar</button>
        </form>
      </div>
    );
  }
}

export default RegistarFuncionario;
