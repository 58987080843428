import React , { Component }  from 'react';
import './RegistarCliente.css';
import axios from 'axios';
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('entrada_access_token')

class RegistarCliente extends Component {

  constructor(props){
    super(props);
    this.state = {
      cartao: '',
      nome: '',
      telefone: '',
      aniversario: '',
      email: '',
      errorMessage: '',
      hasClientID: false,
      username: "entrada",
      token: getAccessToken()
    }

    this.clearForm = this.clearForm.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount(){
    this.setState({cartao: this.props.location.idCartao})
  }

  clearForm(){
    this.setState({
      cartao: '',
      nome: '',
      telefone: '',
      aniversario: '',
      email: '',
      errorMessage: '',
      hasClientID: false
    }, function(){
      // -- focus on form's first input
      this.cartaoinput.focus();
    })
  }

  handleKeyDown(event){
    if(event.key === "Enter"){
      event.preventDefault();
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      // -- don't execute on the last element
      if(index + 1 < form.elements.length){
        if(index === 0){
          this.clienteIDIsInserted(form, index)
        } else{
          form.elements[index + 1].focus();
        }
      }
      else{
        event.currentTarget.click();
      }
    }
  }

  handleFormSubmit(event){
    // -- get json data from url
    event.preventDefault();

    if(this.handleInputValidation()){
      let url = "/api/cliente/update.php";
      // Make a Post Request
      axios({
        method: 'post',
        url: url,
        data: this.state
      })
      .then((res) => {
        this.setState({errorMessage: "Cliente registado com sucesso!"}, function(){
          setTimeout(function(){
            this.clearForm();
          }.bind(this), 2000);
        })
      })
      .catch((error) => {
        if(error.response.status === 503 || error.response.status === 500){
          this.setState({errorMessage: error.response.data.message})
        }
        else{
          this.setState({errorMessage: "Ocorreu um erro a registar o cliente."})
        }
      });
    }

  }

  // -- validate input
  handleInputValidation(){
    if(this.state.cartao.length <= 3 ){
      this.setState({errorMessage: "Número do cartão inválido."});
      return false;
    }
    else if(this.state.telefone && this.state.telefone.length !== 9){
      this.setState({errorMessage: "Por favor introduza um número de telefone válido."});
      return false;
    }
    this.setState({errorMessage: ""});
    return true;
  }

  clienteIDIsInserted(form, index){
    // -- check inserted ID is valid
    let url = "/api/cliente/read_one.php"
    axios.get(url + "?cartao=" + this.state.cartao)
    .then((res) => {
      this.setState({errorMessage: "", nome: res.data.nome, telefone: res.data.telefone, aniversario: res.data.aniversario, email: res.data.email})
      this.setState({hasClientID: true}, () => {
        form.elements[index + 1].focus();
      });
    })
    .catch((error) => {
      this.setState({errorMessage: error.response.data.message})
    })
  }

  _onFocus(e){
    e.currentTarget.type="date";
  }

  render(){
    let hasClientID = !this.state.hasClientID ? "none" : "";

    return (
      <div className="App">
        <h1>Registar Cliente</h1>
        <form method="POST" className="initial-inputs">
          <input name="cartao" type="text" placeholder="Leitura do Cartão" value={this.state.cartao} onChange={e => this.setState({ cartao: e.target.value} )} onKeyDown={this.handleKeyDown} autoFocus={true} disabled={this.state.hasClientID} ref={(input)=>{this.cartaoinput=input;}} autoComplete="new-password" autoCorrect="off" spellCheck="off" />

          <input name="nome" type="text" placeholder="Nome" value={this.state.nome} onChange={e => this.setState({ nome: e.target.value })} style={{display: hasClientID}} autoComplete="new-password" autoCorrect="off" spellCheck="off" />

          <input name="telefone" type="number" placeholder="Telefone" value={this.state.telefone} onChange={e => this.setState({ telefone: e.target.value })} onKeyDown={this.handleKeyDown} style={{display: hasClientID}} autoComplete="new-password" autoCorrect="off" spellCheck="off" />

          <input name="aniversario" placeholder="Data de Nascimento" type="text" value={this.state.aniversario} onChange={e => this.setState({ aniversario: e.target.value })} onKeyDown={this.handleKeyDown} style={{display: hasClientID}} onFocus={this._onFocus}  autoComplete="new-password" autoCorrect="off" spellCheck="off" />

          <input name="email" type="email" placeholder="Email" value={this.state.email} onChange={e => this.setState({ email: e.target.value })} onKeyDown={this.handleKeyDown} style={{display: hasClientID}} autoComplete="new-password" autoCorrect="off" spellCheck="off" />
          <br></br>
          <button type="submit" className="btn" onClick={e => this.handleFormSubmit(e)} style={{display: hasClientID}} >Registar</button>
        </form>
        <button className="btn limpar" onClick={e => this.clearForm()} style={{display: hasClientID}} >Limpar</button>
        { this.state.errorMessage && <h5 className="notification-text">{this.state.errorMessage}</h5>}
      </div>
    );
  }
}

export default RegistarCliente;
