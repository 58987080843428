import React , { Component }  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RowTransacao from "./RowTransacao";
import TablePagination from '@material-ui/core/TablePagination';


class Consultas extends Component {

  constructor(props){
    super(props);
    this.state = {
      transacoes: [""],
      idFuncionario: 0,
      page: 0,
      rowsPerPage: 5
    }

    this.getListData = this.getListData.bind(this);
  }

  componentDidMount(){
    this.setState({idFuncionario: this.props.location.idFuncionario}, function(){
      this.getListData();
    })
  }

  getListData(e = null) {
    if (e)
      e.preventDefault();
    if (typeof this.state.idFuncionario !== 'undefined') {
      // -- get json data from url
      let url = "/api/transacao/get_por_funcionario.php";
      axios.get(url + "?funcionario=" + this.state.idFuncionario)
        .then(res => {
          // get list from database
          this.setState({ transacoes: res.data.records })
          // -- add empty input
        })
        .catch((error) => console.log("Can’t access " + url + " response. " + error))
    }
  }


  handleChangePage = (event, newPage) => {
    this.setState({page: newPage});
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({rowsPerPage: parseInt(event.target.value, 10)})
    this.setState({page: 0});
  };


  render(){
    // https://material-ui.com/pt/components/tables/
    return (
      <div className="App">
        <Link to="/gestao"><h3 className="title">{"<- Voltar a Gestão"}</h3></Link>
        <h1>Consultar por Funcionário</h1>
        <form className="initial-inputs">
          <input className="input-id-funcionario" onChange={(e) => this.setState({idFuncionario: e.target.value})} defaultValue={this.props.location.idFuncionario} placeholder="Número de Funcionário" autoFocus autoComplete="new-password" autoCorrect="off" spellCheck="off" />
          <button className="btn button-reload-data" type="submit" onClick={this.getListData}>Obter</button>
        </form>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell>Tipo</TableCell>
                <TableCell align="right">Valor</TableCell>
                <TableCell align="right">Funcionário</TableCell>
                <TableCell align="right">Cliente</TableCell>
                <TableCell align="right">Data</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
             {this.state.transacoes.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
                return <RowTransacao key={row.name} row={row} />
              })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 15, 50, 100]}
            component="div"
            count={this.state.transacoes.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </TableContainer>
      </div>
    );
  }
}

export default Consultas;
