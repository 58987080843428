import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {Link} from 'react-router-dom'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});


function RowFuncionario(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  // const [transacoes, setTransacoes] = React.useState([]);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root} >
        <TableCell component="th" scope="row">
          {row.id}
        </TableCell>
        <TableCell align="right">{row.nome}</TableCell>
        <TableCell align="right">{row.telefone}</TableCell>
        <TableCell align="right">{row.email}</TableCell>
        <TableCell align="right">{row.codigo}</TableCell>
        <TableCell align="right">{row.registado}</TableCell>
        <TableCell component={Link} to={{pathname:"/gestao/gerir-funcionario/" + row.id, funcionario:row}} align="right">
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <EditIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}


export default RowFuncionario;