import React , { Component }  from 'react';
import { Link  } from 'react-router-dom';
import './Gestao.css';
import axios from 'axios';
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('gestao_access_token')
const getAdminUsername = () => Cookies.get('gestao_admin_username')

class Consultas extends Component {
  constructor(props){
    super(props);
    this.state = {
      clients: [""],
      username: "",
      password: "",
      isAuthenticated: false
    }
  }

  componentDidMount(){
    this.setState({idCartao: this.props.location.idCartao})
    this.setState({idFuncionario: this.props.location.idFuncionario})
    this.checkLoginAdmin();
  }

  handleFormSubmit(event){
    // -- get json data from url
    event.preventDefault();

    let url = "/api/config/check-admin.php";
    // Make a Post Request
    axios({
      method: 'post',
      url: url,
      data: {
        username: this.state.username,
        password: this.state.password
      }
    })
    .then((res) => {
      this.setState({errorMessage: ""})
      this.setState({isAuthenticated: true})

      // -- generate expiry date
      const expires = (60 * 60 * 24 * 6) * 1000
      const inAWeek = new Date(new Date().getTime() + expires)

      let token = res.data.token
      Cookies.set('gestao_access_token', token, {expires: inAWeek})
      Cookies.set('gestao_admin_username', this.state.username, {expires: inAWeek})
    })
    .catch((error) => {
      this.setState({errorMessage: error.response.data.message})
    });
  }

  checkLoginAdmin(){
    if(getAccessToken()){
      let url = "/api/config/check-token.php";
      // Make a Post Request
      axios({
        method: 'post',
        url: url,
        data: {
          username: getAdminUsername(),
          token: getAccessToken()
        }
      })
      .then((res) => {
        console.log(res.data)
        this.setState({errorMessage: ""})
        this.setState({isAuthenticated: true})
      })
      .catch((error) => {
        if(error.response.status !== 200){
          this.setState({errorMessage: "A Sessão expirou. Inicie sessão novamente."})
        }
      });
    }
  }

  render(){
    return (
      // -- check if admin is authenticated
      // -- show backoffice page if it is
      // -- show login form if it isn't
      // ---- if(isAuth) ? showBackoffice : (else) showLoginForm
      <div>
      { this.state.isAuthenticated ? (
      <div className="App">
        <h1>Gestão</h1>
        <div className="section gestao-extratos">
          <h1 className="section-title">Extratos</h1>
          <Link className="gestao-open-page" to={{pathname: "/gestao/consultar-tudo"}} >Ver Todos os Clientes</Link>
          <br/>
          <Link className="gestao-open-page" to={{pathname: "/gestao/consultar-por-cartao", idCartao: this.state.idCartao, gestao: true}}>Extrato por Cartão</Link>
          <br/>
          <Link className="gestao-open-page" to={{pathname: "/gestao/consultar-por-funcionario", idFuncionario: this.state.idFuncionario}}>Extrato por Funcionário</Link>
          <br/>
          <Link className="gestao-open-page" to="/gestao/consultar-por-dia">Extrato por Dia</Link>
        </div>

        <div className="section gestao-funcionarios">
          <h1 className="section-title">Funcionários</h1>
          <Link className="gestao-open-page" to={{pathname: "/gestao/registar-funcionario"}}>Registar Funcionário</Link>
          <br/>
          <Link className="gestao-open-page" to={{pathname: "/gestao/gerir-funcionarios"}}>Gerir Funcionários</Link>
        </div>

        <div className="section gestao-configuracoes">
          <h1 className="section-title">Configurações</h1>
          <Link className="gestao-open-page" to={{pathname: "/gestao/registar-cartao"}}>Registar Cartão</Link>
          <br/>
          <Link className="gestao-open-page" to={{pathname: "/gestao/alterar-password"}}>Alterar Password</Link>
          <br/>
          <Link className="gestao-open-page" to={{pathname: "/gestao/alterar-percentagem"}}>Alterar Percentagem</Link>
        </div>

            <div className="section gestao-exportar">
              <h1 className="section-title">Exportar</h1>
              <Link className="gestao-open-page" to={{ pathname: "/gestao/exportar-contactos" }}>Contactos</Link>
        </div>

      </div>
      ) : (
      <div className="App">
        <h1>Gestão</h1>
        <h2>Iniciar Sessão</h2>
        <form>
          <input type="text" className="gestao-login-username" placeholder="Nome de Utilizador" onChange={e => this.setState({ username: e.target.value })} maxLength={100} minLength={0}/>
          <input type="password" className="gestao-login-password" placeholder="Palavra-Passe" onChange={e => this.setState({ password: e.target.value })} maxLength={100} minLength={0}/>
          <input type="submit" className="btn" value="Entrar" onClick={e => this.handleFormSubmit(e)}/>
        </form>
        { this.state.errorMessage && <h5 className="notification-text">{this.state.errorMessage}</h5>}
      </div>
      )}
      </div>
    )
  }
}

export default Consultas;
