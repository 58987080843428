import React , { Component }  from 'react';
import axios from 'axios';
import {Link} from 'react-router-dom'
import Cookies from 'js-cookie'

export const getAccessToken = () => Cookies.get('gestao_access_token')
const getAdminUsername = () => Cookies.get('gestao_admin_username')

class RegistarCartao extends Component {

  constructor(props){
    super(props);
    this.state = {
      cartao: "",
      validation: "",
      username: getAdminUsername(),
      token: getAccessToken()
    }

    this.clearForm = this.clearForm.bind(this);
  }

  clearForm(){
    this.setState({
      cartao: '',
      validation: ""
    })
  }

  handleFormSubmit(event){
    // -- get json data from url
    event.preventDefault();

    if(this.state.cartao.length <= 3){
      this.setState({validation: "Cartão inválido. Número demasiado curto."})
    } else{
      let url = "/api/cliente/create.php";
      // Make a Post Request
      axios({
        method: 'post',
        url: url,
        data: this.state
      })
      .then((res) => {
        this.setState({validation: res.data.message}, function(){
          setTimeout(function(){
            this.clearForm();
          }.bind(this), 500);
        })
      })
      .catch((error) => {
        if(error.response.status === 503 || error.response.status === 400){
          this.setState({validation: error.response.data.message})
        }
        if(error.response.status === 500){
          // já existe um cliente com este cartão
          // -- ou
          // não é um número
          this.setState({validation: "Cartão já registado."})
        }
        setTimeout(function(){
          this.setState({validation: ""})
        }.bind(this), 2000);
      });
    }

  }

  render(){
    return (
      <div className="App">
        <Link to="/gestao"><h3 className="title">{"<- Voltar a Gestão"}</h3></Link>
        <h1>Registar Cartão</h1>
        <form method="POST" className="initial-inputs">
          <input name="cartao" type="number" maxLength="10" placeholder="Leitura do Cartão" value={this.state.cartao} onChange={e => this.setState({ cartao: e.target.value })} autoFocus autoComplete="new-password" autoCorrect="off" spellCheck="off" />
          <br></br>
          <h5 className="notification-text">{this.state.validation}</h5>
          <br/>
          <button type="submit" className="btn" onClick={e => this.handleFormSubmit(e)}>Registar</button>
        </form>
      </div>
    );
  }
}

export default RegistarCartao;
